import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@ui-components/ContentText'
import Reveal from '@ui-components/Reveal'
import Divider from '@ui-components/Divider'
import ContentButtons from '@ui-components/ContentButtons'
import WithDefaultContent from '@ui-blocks/WithDefaultContent'
import ContentForm from '@ui-components/ContentForm'

const HeroBlock04 = ({
  content: { text = [], form, buttons },
  reverse,
  locales
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `45%`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`,
          margin: [0, 'auto']
        }}
      >
        {form && (
          <ContentForm
            form={form}
            id={`form.contact`}
            locales={locales}
          />
        )}
      </Box>
      <Box
        sx={{
          flexBasis: `20%`,
          textAlign: [`center`, null, null, `left`],
          margin: 'auto',
        }}
      >
        <Reveal effect='fadeInDown'>
          <ContentText content={text} />
        </Reveal>
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            {buttons && (
              <>
                <Divider space={3} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(HeroBlock04)
